/**
 * Used to send socket commands
 * Zoey Désautels (zoey.desautels@konek.ai), 2020
 */

import axios from 'axios';
import { bus, Colors } from "@/plugins/events";

export interface SocketData {
  devices: string;
  method: string;
  params?: string[];
  reply?: boolean;
}

export default class Socket {
  /**
   * Send socket command
   */
  async execute(data: SocketData): Promise<string[] | void> {
    try {
      const response = await axios.post("/v1/socket/message", data);

      bus.$emit('show-snack', {
        text: "Socket command executed successfully.",
        color: Colors.SUCCESS
      });

      if (data.reply === true) {
        const replies: string[] = response.data.replies;

        return replies;
      }
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't execute socket command.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }
}