/**
 * Used to fetch, upload and edit videos
 */

import axios from 'axios';
import { bus, Colors } from "@/plugins/events";

import { Video } from '@/entities/videos/Video';

/**
 * VideoData that can be updated
 */
export interface VideoData {
  name?: string,
  location?: string
}

export default class Videos {
  /**
   * List all the videos available to the user
   */
  async list(): Promise<Video[]> {
    try {
      const response = await axios.get("/v1/videos");
      const videos: Video[] = response.data.videos; 

      return videos;
    } catch {
      bus.$emit("show-snack", {
        text: "Couldn't fetch videos",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  /**
   * Upload an video
   */
  async upload(data: any): Promise<Video> {
    try {
      const response = await axios.post("/v1/videos/upload", data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      bus.$emit("show-snack", {
        text: "Video was uploaded.",
        color: Colors.SUCCESS
      });

      const video: Video = response.data.video;

      return video;
    } catch {
      bus.$emit("show-snack", {
        text: "Video couldn't be uploaded.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  /**
   * Edit an video
   */
  async edit(file_name: string, data: VideoData) {
    try {
      await axios.patch(`/v1/videos/${file_name}`, data);

      bus.$emit("show-snack", {
        text: "Video was updated.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit("show-snack", {
        text: "Couldn't update the video!",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  
}