/**
 * Used to fetch, patch and delete locations
 * Zoey Désautels (zoey.desautels@konek.ai), 2020
 */

import axios from 'axios';
import { bus, Colors } from "@/plugins/events";

import { Location } from "@/entities/Location";

/**
 * Location data you can update
 */
export interface LocationData {
  name?: string;
  uuid?: string;
  code_name?: string;
  public_ip?: string;
  timezone?: string;
  locale?: string;
  weather_code?: number;
  wifi_ssid?: string;
  wifi_password?: string;
  services_enabled?: boolean;
  location_proxy?: string;
  banner_url?: string;
  home_buttons?: string[];
  home_banner?: string;
  cast_enabled?: boolean;
  airplay_enabled?: boolean;
  resto_location_proxy?: string;
  ads_enabled?: boolean;
  welcome_music_enabled?: boolean;
  welcome_music_channel?: string;
  welcome_music_url?: string;
}

export default class Locations {
  /**
   * Get a list of locations available to the users
   */
  async list(): Promise<Location[]> {
    try {
      const response = await axios.get("/v1/locations");
      const locations: Location[] = response.data.locations;

      return locations;
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't fetch locations.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  /**
   * Update a location
   */
  async edit(uuid: string, data: LocationData): Promise<void> {
    try {
      await axios.patch(`/v1/locations/${uuid}`, data);

      bus.$emit("show-snack", {
        text: "Location was updated.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit("show-snack", {
        text: "Couldn't update this location",
        color: Colors.ERROR
      });

      throw Error();
    }
  }
}
