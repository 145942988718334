import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import { User } from '@/entities/User';
import { Location } from "@/entities/Location";

import Locations from '@/plugins/services/locations';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    login_token: (localStorage.token ? localStorage.token : null) as String | null,
    user: null as User | null,

    locations: [] as Location[],
    selected_location: (localStorage.location ? localStorage.location : null) as String | null,
  },

  getters: {
    token: state => {
      return state.login_token;
    },

    user: state => {
      return state.user;
    },

    isLoggedIn: state => {
      return state.user !== null;
    },

    isAdmin: state => {
      if (state.user === null) return false;

      return ['admin', 'operator'].includes(state.user.type);
    },

    locations: state => {
      return state.locations;
    },

    selected_location: state => {
      return state.selected_location;
    },

    location: state => {
      if (state.selected_location === null) return state.locations[0]?state.locations[0]:{
        name: "N/A",
        code_name: "N/A"
      };
      let location = state.locations.find(location => location.uuid === state.selected_location) as Location;
      if (location === undefined) return state.locations[0]?state.locations[0]:{
        name: "N/A",
        code_name: "N/A"
      };
      return location;
    },
  },

  mutations: {
    login(state, payload) {
			state.user = payload;
			state.login_token = payload.token;
		},

		logout(state) {
			state.user = null;
			state.login_token = null;
    },

    setLocations(state, payload) {
      state.locations = payload.locations;

      if (state.selected_location === null) {
        state.selected_location = payload.locations[0].uuid;
      }
    },

    setLocation(state, payload) {
      state.selected_location = payload.uuid;
    }
  },

  actions: {
    async login({ commit }, payload) {
      commit("login", payload);

      // Save the token locally
			localStorage.setItem('token', payload.token);

			// Add the token to the headers
      axios.defaults.headers.common['authorization'] = payload.token;

      this.dispatch('loadLocations');
    },

    logout({ commit }) {
      commit("logout");

      // Remove the saved token
			localStorage.removeItem('token');

      localStorage.removeItem('location');

			// Remove the token from the headers
			delete axios.defaults.headers.common['authorization'];
    },

    setLocation({ commit }, payload) {
      commit("setLocation", payload);

      localStorage.setItem('location', payload.uuid);
    },
    setLocations({ commit }, payload) {
      commit("setLocations", payload);
    },

    async loadLocations({ commit }) {
      const locations = await new Locations().list();

      commit("setLocations", {
        locations
      });
    }
  }
});
