/**
 * Used to fetch, upload and edit images
 * Zoey Désautels (zoey.desautels@konek.ai), 2020
 */

import axios from 'axios';
import { bus, Colors } from "@/plugins/events";

import { PdfMenu } from '@/entities/pdfMenu/PdfMenu';

/**
 * ImageData that can be updated
 */
export interface PdfMenuData {
  name?: string,
  category?: string,
  location?: string
}

export default class PdfMenus {
  /**
   * List all the images available to the user
   */
  async list(): Promise<PdfMenu[]> {
    try {
      const response = await axios.get("/v1/menus");
      const menus: PdfMenu[] = response.data.menus; 

      return menus;
    } catch {
      bus.$emit("show-snack", {
        text: "Couldn't fetch images",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  /**
   * Upload an image
   */
  async upload(data: any): Promise<PdfMenu> {
    try {
      const response = await axios.post("/v1/menus/upload", data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      bus.$emit("show-snack", {
        text: "PdfMenu was uploaded.",
        color: Colors.SUCCESS
      });

      const menu: PdfMenu = response.data.menu;

      return menu;
    } catch {
      bus.$emit("show-snack", {
        text: "PdfMenu couldn't be uploaded.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  /**
   * Edit an image
   */
  async edit(file_name: string, data: PdfMenuData) {
    try {
      await axios.patch(`/v1/menus/${file_name}`, data);

      bus.$emit("show-snack", {
        text: "PdfMenu was updated.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit("show-snack", {
        text: "Couldn't update the image!",
        color: Colors.ERROR
      });

      throw Error();
    }
  }
}