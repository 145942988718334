/**
 * Global plugin to add helpers functions to vue
 * Zoey Désautels (zoey.desautels@konek.ai), 2020
 */
import Vue from 'vue';
import { bus, Colors } from "@/plugins/events";

import { Image } from '@/entities/images/Image';
import { Video } from '@/entities/videos/Video';
import { PdfMenu } from '@/entities/pdfMenu/PdfMenu';
import { Audio } from '@/entities/audio/Audio';
type Media = Video | Image;

export class Helpers {
  /**
   * Filter a list of images
   */
  filterImages(input: Image[], query: string, categories: string[]): Image[] {
    if (query.trim() === "" && categories.length == 0) return input;

    let filtered = input.filter((image) => {
      return image.name.toLowerCase().includes(query.trim().toLowerCase());
    });

    if (categories.length > 0) {
      filtered = filtered.filter((image) => {
        return categories.includes(image.category);
      });
    }

    return filtered;
  }
  /**
   * Filter a list of menus
   */
  filterMenus(input: PdfMenu[], query: string): PdfMenu[] {
    if (query.trim() === "") return input;

    let filtered = input.filter((menu) => {
      return menu.name.toLowerCase().includes(query.trim().toLowerCase());
    });

    return filtered;
  }

  /**
   * Filter a list of audios
   */
  filterAudios(input: Audio[], query: string): Audio[] {
    if (query.trim() === "") return input;

    let filtered = input.filter((audio) => {
      return audio.name.toLowerCase().includes(query.trim().toLowerCase());
    });

    return filtered;
  }

  filterVideos(input: Video[], query: string): Video[] {
    if (query.trim() === "") return input;

    let filtered = input.filter((video) => {
      return video.name.toLowerCase().includes(query.trim().toLowerCase());
    });

    return filtered;
  }

  filterMedias(input: Media[], query: string): Media[] {
    if (query.trim() === "") return input;

    let filtered = input.filter((image) => {
      return image.name.toLowerCase().includes(query.trim().toLowerCase());
    });

    return filtered;
  }

  /**
   * Copy something to the clipboard
   */
  async copy(text: string) {
    navigator.permissions.query({name: 'clipboard-write' as PermissionName}).then((result) => {
      if (result.state === 'granted') {
        navigator.clipboard.writeText(text).then(() => {
          bus.$emit('show-snack', {
            text: `Text was copied to your clipboard.`,
            color: Colors.SUCCESS
          });
        });
      } else if (result.state === 'prompt') {
        bus.$emit('show-snack', {
          text: `Unable to copy text to your clipboard.`,
          color: Colors.ERROR
        });
      }
    });
  }
}

const KonekHelpers = {
  install(Vue: any) {
    Vue.prototype.$helpers = new Helpers();
  }
}

Vue.use(KonekHelpers);
