/**
 * Used to fetch, upload and edit kokotes
 * Zoey Désautels (zoey.desautels@konek.ai), 2020
 */

import axios from 'axios';
import { bus, Colors } from "@/plugins/events";

import { Location } from '@/entities/Location';
import { Kokote } from '@/entities/devices/Kokote';

/**
 * KokoteData that can be updated
 */
export interface KokoteData {

}

export default class Kokotes {
  /**
   * Fetch the kokotes from a location
   */
  async listFromLocation(location: Location) {
    try {
      bus.$emit('show-snack', {
        text: "Fetching devices.",
        color: "success",
        timeout: true
      });

      const response = await axios.get(`/v1/kokotes/location/${location.uuid}`);
      const kokotes: Kokote[] = response.data.kokotes;

      return kokotes;
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't fetch the devices from this location.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  /**
   * Delete a kokote
   */
  async delete(kokote: Kokote): Promise<void> {
    try {
      await axios.delete(`/v1/kokotes/${kokote.id}`);

      bus.$emit('show-snack', {
        text: "The device was deleted.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't delete this device.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }
}