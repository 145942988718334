export interface UnifiUrlParams {
  /// The Site ID
  siteID: string;
  /// The access point MAC address.
  ap: string;
  /// The user's MAC address.
  id: string;
  /// The unix timestamp of the redirect.
  t: string
  /// The original URL requested by the user.
  url: string;
  /// The ssid of the network the user is trying to connect to.
  ssid: string;
}

import Controller from 'unifi-client';
import axios from "axios";
import { PortalSettings } from '@/entities/PortalSettings';
import { WifiVoucher } from '@/entities/WifiVoucher';
export enum EmailResults{
  Success = 0,
  Error = 1,
  EmailAlreadyValidated = 2,
  FreeConnectionAlreadyUsed = 3,
}
export default class Unifi {
  async initUnifi(host: string, username: string, password: string): Promise<Controller> {

    const controller = new Controller({
      url: host,
      strictSSL: false,
      username: username,
      password: password,
  });
    let user = await controller.login();
    console.log(controller);
    console.log(user);
    controller.getSites().then((sites) => {
      console.log(sites);
      console.log(sites[0].clients)
    });
    return controller;
  }
  async validateRoomNumberLogin(siteID: string, roomNumber: string, lastName: string) {
    return axios.post('/v1/unifi/validate/room-number-login', {site_id: siteID, room_number: roomNumber, last_name: lastName}).then((res) => {
      if (res.status === 200) {
        return true;
      }
      return false;
    }).catch((err) => {
      console.log(err);
      return false;
    });
  }
  async sendConnectionEmail(address: string, time_limit: number, mac: string, ap: string, free_connection:boolean, site_id: string) {

    return axios.post('/v1/unifi/portal/email', {address, time_limit, mac, ap, free_connection, site_id}).then((res) => {
      if (res.status === 200) {
        return EmailResults.Success;
      }
      if(res.status === 222){ //Email already validated
        return EmailResults.EmailAlreadyValidated;
      }
      if(res.status === 223){ //Free Connection already used
        return EmailResults.FreeConnectionAlreadyUsed;
      }
      return EmailResults.Error;
    }).catch((err) => {
      console.log(err);
      return EmailResults.Error;
    });
  }
  async validatePassword(siteID: string, password: string) {
    return axios.post('/v1/unifi/validate/password', {site_id: siteID, password: password}).then((res) => {
      if (res.status === 200) {
        return true;
      }
      return false;
    }).catch((err) => {
      console.log(err);
      return false;
    });
  }
  async validateVoucher(siteID: string, voucher: string) {
    return axios.post('/v1/unifi/validate/voucher', {site_id: siteID, voucher: voucher}).then((res) => {
      if (res.status === 200) {
        return res.data as WifiVoucher;
      }
      return null;
    }).catch((err) => {
      console.log(err);
      return null;
    });

  }
  async generateVoucher(number: number, duration: number, location_uuid: string, usages: number|null) {
    return axios.post(`/v1/vouchers/generate`, {location_uuid, duration, number, usages:usages}).then((res) => {
      if (res.status === 200) {
        return true
      }
      return false;
    }).catch((err) => {
      console.log(err);
      return false;
    });

  }
  async useVoucher(siteID: string, voucher: string) {
    return axios.post(`/v1/vouchers/use/${voucher}`, {site_id: siteID,}).then((res) => {
      if (res.status === 200) {
        return true
      }
      return false;
    }).catch((err) => {
      console.log(err);
      return false;
    });

  }
  async updateVoucher(id: number, changes: WifiVoucher) {
    return axios.patch(`/v1/vouchers/${id}`, {changes: changes}).then((res) => {
      if (res.status === 200) {
        return true
      }
      return false;
    }).catch((err) => {
      console.log(err);
      return false;
    });

  }
  async deleteVoucher(id: number) {
    return axios.delete(`/v1/vouchers/${id}`).then((res) => {
      if (res.status === 200) {
        return res.data as WifiVoucher;
      }
      return null;
    }).catch((err) => {
      console.log(err);
      return null;
    });

  }
  async getVouchersByLocation(location_uuid: string) {
    return axios.get(`/v1/vouchers/location/${location_uuid}`).then((res) => {
      if (res.status === 200) {
        return res.data as WifiVoucher[];
      }
      return [] as WifiVoucher[];
    }).catch((err) => {
      console.log(err);
      return [] as WifiVoucher[];
    });

  }
  async authorizeGuest(siteID: string, mac:string, ap:string, minutes:number){
    return axios.post('/v1/unifi/authorize', {siteID: siteID, mac: mac, ap: ap, minutes: minutes}).then((res) => {
      if (res.status === 200) {
        return true;
      }
      return false;
    }).catch((err) => {
      console.log(err);
      return false;
    });
  }
  async createPortalSettings(site_id:string, settings:PortalSettings){
    return axios.post('/v1/unifi/portal/settings', {site_id, settings}).then((res) => {
      if (res.status === 200) {
        return res.data as PortalSettings;
      }
      return null;
    }).catch((err) => {
      console.log(err);
      return null;
    });
  }
  async getPortalSettings(site_id:string){
    return axios.get(`/v1/unifi/portal/settings/${site_id}`).then((res) => {
      if (res.status === 200) {
        return res.data as PortalSettings;
      }
      console.error(res.data);
      return null;
    }).catch((err) => {
      console.error(err);
      return null;
    });
  }

  async updatePortalSettings(site_id:string, settings:PortalSettings){
    return axios.patch(`/v1/unifi/portal/settings/${site_id}`, {settings}).then((res) => {
      if (res.status === 200) {
        return true;
      }
      console.error(res.data);
      return false;
    }).catch((err) => {
      console.error(err);
      return false;
    });
  }
}

