/**
 * Used to fetch, patch and delete restaurants
 * Zoey Désautels (zoey.desautels@konek.ai), 2020
 */

import axios from 'axios';
import { bus, Colors } from "@/plugins/events";

import { Location } from '@/entities/Location';
import { Restaurant } from '@/entities/restaurant/Restaurant';
import { Text } from '@/entities/Text';
import { Food } from '@/entities/restaurant/Food';
import { PdfMenu } from '@/entities/pdfMenu/PdfMenu';

export interface RestaurantData {
  name?: string;
  subtitle?: Text;
  description?: Text;
  rating?: number;
  schedule?: string;
  address?: string;
  phone_number?: string;
  price?: number;
  cdcIndex?: number;
  proximIndex?: number;
  type?: "hotel" | "nearby";

  menu?: PdfMenu|null;
  uuid?: string;
  food?: Food[];
  images?: number[];
  ratings?: number[];
  noHours?: boolean;
}

export default class Restaurants {
  async list(): Promise<Restaurant[]> {
    try {
      const response = await axios.get(`/v1/restaurants`);
      const restaurants: Restaurant[] = response.data.restaurants;

      return restaurants;
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't fetch restaurants.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  async getById(id: number): Promise<Restaurant> {
    try {
      const response = await axios.get(`/v1/restaurants/${id}`);
      const resstaurant: Restaurant = response.data.restaurant;
      console.log(resstaurant.businessHours);

      return resstaurant;
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't fetch restaurant.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  async getByLocation(location: Location): Promise<Restaurant[]> {
    const restaurants: Restaurant[] = [];
    function addResto(obj: any) {
      try {
        // console.log(`Added`, obj);
        restaurants.push(obj);
      } catch (error) {
        console.log(`${obj} is not a valid restaurant.`)
        console.log(error);
      }
    }

    try {
      const response = await axios.get(`/v1/restaurants/list/${location.uuid}`);
      response.data.restaurants.forEach((restaurant: any) => {
        addResto(restaurant)
      });

      return restaurants;
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't fetch restaurant.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  async create(data: RestaurantData): Promise<void> {
    try {
      await axios.post(`/v1/restaurants`, data);

      bus.$emit('show-snack', {
        text: "Restaurant was created.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't create restaurant.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  async edit(restaurant: Restaurant, data: RestaurantData): Promise<void> {
    try {
      await axios.patch(`/v1/restaurants/${restaurant.id}`, data);

      bus.$emit('show-snack', {
        text: "Restaurant was updated.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't edit restaurant.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  async delete(restaurant: Restaurant): Promise<void> {
    try {
      await axios.delete(`/v1/restaurants/${restaurant.id}`);

      bus.$emit('show-snack', {
        text: "Restaurant was deleted.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't delete restaurant.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  toData(restaurant: Restaurant, uuid?: string): RestaurantData {
    let data = {} as RestaurantData;
    data = {
      ...restaurant,
      uuid,
      images: restaurant.images?.map(x => x.id) ?? [],
      menu: restaurant.menu,
    };

    console.log("Data");
    console.log(data);

    return data;
  }
}
