/**
 * Used to fetch, patch and delete snacks
 * Zoey Désautels (zoey.desautels@konek.ai), 2020
 */

import axios from 'axios';
import { bus, Colors } from "@/plugins/events";
import { Snack } from '@/entities/restaurant/Snack';
import { Location } from '@/entities/Location';
import { Text } from '@/entities/Text';
import { Image } from '@/entities/images/Image';

export interface SnackData {
  name?: Text;
  category?: string;
  price?: number;
  type?: "food" | "drink";
  image?: Image;
  location?: string;
}

export default class Snacks {
  async list(): Promise<Snack[]> {
    try {
      const response = await axios.get(`/v1/snack`);
      const snacks: Snack[] = response.data.snacks;

      return snacks;
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't fetch snacks.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  async getById(id: number): Promise<Snack> {
    try {
      const response = await axios.get(`/v1/snack/${id}`);
      const snack: Snack = response.data.snack;
      
      return snack;
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't fetch snack.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  async getByLocation(location: Location): Promise<Snack[]> {
    try {
      const response = await axios.get(`/v1/snack/list/${location.uuid}`);
      const snacks: Snack[] = response.data.snacks;

      return snacks;
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't fetch snacks.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  async create(data: SnackData): Promise<void> {
    try {
      await axios.post(`/v1/snack`, data);

      bus.$emit('show-snack', {
        text: "Snack was created.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't create snack.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  async edit(snack: Snack, data: SnackData): Promise<void> {
    try {
      await axios.patch(`/v1/snack/${snack.id}`, data);

      bus.$emit('show-snack', {
        text: "Snack was updated.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't update snack.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  async delete(snack: Snack): Promise<void> {
    try {
      await axios.delete(`/v1/snack/${snack.id}`);

      bus.$emit('show-snack', {
        text: "Snack was deleted.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't delete snack.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }
}