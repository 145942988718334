
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import moment from "moment";

import { Location } from '@/entities/Location';

@Component({
  computed: mapGetters([
    'isLoggedIn',
    'location'
  ]),
})
export default class Home extends Vue{
  totalDevices = 0;
  onlineDevices = 0;

  location!: Location;
  isLoggedIn!: boolean;

  mounted() {
    this.fetchDevicesList();
  }

  @Watch("location")
  onPropertyChanged() {
    this.fetchDevicesList();
  }

  get buttonColor() {
    return this.$vuetify.theme.dark ? "light-blue accent-4" : "deep-purple accent-4";
  }

  async fetchDevicesList() {
    if (this.location?.kokotes === undefined) return;

    this.totalDevices = this.location.kokotes.length;

    this.location.kokotes.forEach((kokote) => {
      const elapsed = moment().diff(moment.utc(kokote.updated_at), 'minutes');

      if (elapsed < 30) {
        this.onlineDevices += 1;
      }
    });
  }
}
