/**
 * Vue packages
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

/**
 * Plugins
 */
import './plugins/axios';
import './plugins/api';
import './plugins/helpers';
import vuetify from './plugins/vuetify';
import {i18n} from './plugins/i18n';

/**
 * Styling
 */
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
