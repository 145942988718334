/**
 * Used to login, logout and manage users
 * Zoey Désautels (zoey.desautels@konek.ai), 2020
 */

import axios from 'axios';
import { bus, Colors } from "@/plugins/events";
import { User } from '@/entities/User';

/**
 * User data you can edit
 */
export interface UserData {
  email?: string;
  name?: string;
  password?: string;
  role?: "operator" | "admin" | "advertiser" | "manager" | "device";
}

export default class Users {
  /**
   * Login using an email and password
   */
  async login(email: string, password: string): Promise<User> {
    try {
      const response = await axios.post("/v1/users/login", {
        email,
        password
      });

      const user: User = response.data.user;

      return user;
    } catch(error:any) {
      bus.$emit("show-snack", {
        // Do not show the API badRequest Message and make our own
        //text: error.response.data.message,
        text: "Wrong Password or Email",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  /**
   * Login using a token
   */

  async loginWithToken(token: string): Promise<User> {
    try {
      const response = await axios.post(`/v1/users/login/${token}`);
      const user: User = response.data.user;

      return user;
    } catch {
      bus.$emit("show-snack", {
        text: "Couldn't login using that token.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  /**
   * Register an account
   */
  async register(name: string, email: string, password: string, uuid: string, role: string): Promise<void> {
    try {
      await axios.post("/v1/users", {
        name,
        email,
        password,
        uuid,
        role
      });

      bus.$emit("show-snack", {
        text: "Account was created.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit("show-snack", {
        text: "Account couldn't be created.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  /**
   * Edit an user
   */
  async edit(id: number, data: UserData): Promise<void> {
    try {
      await axios.patch(`/v1/users/${id}`, data);

      bus.$emit('show-snack', {
        text: "User was updated.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't update the user.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }
}
