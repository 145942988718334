<template>
  <v-snackbar
    v-model="show"
    :color="color"
    :timeout="3000"
  >
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn
        dark
        text
        v-bind="attrs"
        @click="show = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'

import { bus, Colors } from "@/plugins/events";

@Component
export default class Snackbar extends Vue{
  show = false;
  text = "";
  color = Colors.SUCCESS

  mounted() {
    bus.$on("show-snack", options => {
      this.text = options.text;
      this.color = options.color;

      this.show = true;

      if (options.timeout) {
        setTimeout(() => {
          this.show = false;
        }, 3000);
      }
    });
  }
}
</script>

<style>

</style>