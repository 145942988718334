
import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';

@Component
export default class IconButton extends Vue {
  @Prop({ required: true })
  path!: string;

  @Prop({ required: true })
  icon!: string;
  
  @Prop({ required: true })
  text!: string;
}
