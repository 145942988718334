/**
 * Used to fetch, upload and edit services
 * Zoey Désautels (zoey.desautels@konek.ai), 2020
 */

import axios from 'axios';
import { bus, Colors } from "@/plugins/events";

import { Service } from '@/entities/directory/Service';
import { Location } from '@/entities/Location';
import { ServiceDetail } from '@/entities/directory/ServiceDetail';

/**
 * Services data that can be edited
 */
export interface ServicesData {
  name?: string;
  description?: string;
  image_url?: string;
  lang?: "en" | "fr";
  weight: number;
  details?: ServiceDetail[];
  enabled?: boolean;
}

export default class Services {

  /**
   * Get a service via its id
   */
  async getById(id: number): Promise<Service> {
    try {
      const response = await axios.get(`/v1/services/${id}`);
      const service: Service = response.data.service;

      return service;
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't fetch the service.",
        color: Colors.ERROR
      });

      throw Error;
    }
  }

  /**
   * List all the available services from a location
   */
  async listByLocation(location: Location): Promise<Service[]> {
    try {
      const response = await axios.get(`/v1/services/location/${location.uuid}`);
      const services: Service[] = response.data.services;

      return services;
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't fetch services.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  /**
   * Create a Service
   */
  async create(name: string, image_url: string, description: string, lang: string, weight: number, location_id: string, details: ServiceDetail[]): Promise<void> {
    try {
      await axios.post('/v1/services', {
        name,
        image_url,
        description,
        lang,
        location_id,
        details,
        weight
      });

      bus.$emit('show-snack', {
        text: "Service was created.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't create this service.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  /**
   * Edit a Service
   */
  async edit(service: Service, data: ServicesData): Promise<void> {
    try {
      await axios.patch(`/v1/services/${service.id}`, data);

      bus.$emit('show-snack', {
        text: "Service was updated.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't update this service.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  /**
   * Delete a Service
   */
  async delete(service: Service): Promise<void> {
    try {
      await axios.delete(`/v1/services/${service.id}`);

      bus.$emit('show-snack', {
        text: "Service was deleted.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't delete this service.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }
}
