/**
 * Used to fetch, patch and delete food
 * Zoey Désautels (zoey.desautels@konek.ai), 2020
 */

import axios from 'axios';
import { bus, Colors } from "@/plugins/events";

import { Text } from '@/entities/Text';
import { Food } from '@/entities/restaurant/Food';

export interface FoodData {
  name?: Text;
  category?: string;
  price?: number;
  type?: "food" | "drink";
  image?: number;
  restaurant?: number;
}

export default class Foods {
  async create(data: FoodData): Promise<Food> {
    try {
      const response = await axios.post(`/v1/food/`, data);
      const food: Food = response.data.food;

      bus.$emit('show-snack', {
        text: "Food was created.",
        color: Colors.SUCCESS
      });

      return food;
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't create food.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  async edit(food: Food, data: FoodData): Promise<void> {
    try {
      await axios.patch(`/v1/food/${food.id}`, data);

      bus.$emit('show-snack', {
        text: "Food was updated.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't update food.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  async delete(food: Food): Promise<void> {
    try {
      await axios.delete(`/v1/food/${food.id}`);

      bus.$emit('show-snack', {
        text: "Food was deleted.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't delete food.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }
}