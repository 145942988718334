import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#2A3F64",
        accent: "#FFEECC"
      },
      dark: {
        primary: {
            base: "#2A3F64",
            darken1: "#04c279"
        },
        accent: "#250032",
        secondary: "#97812F",
        info: {
            base: "#1FFFF1",
            darken1: "#450b5a",
            darken2: "#1125c0",
            darken3: "#40bfa4"
        },
        anchor: "#1FFFF1",
        links: "#76B5C5"
      }
    },
    dark: false,
  },
  icons: {
    iconfont: 'mdi',
  },
});
