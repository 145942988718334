
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex';

import IconButton from "@/components/buttons/IconButton.vue";
import HotelList from "@/components/lists/HotelList.vue";

import { Location } from '@/entities/Location';

@Component({
  computed: mapGetters([
    'locations',
    'location',
    'isAdmin'
  ]),

  components: {
    IconButton,
    HotelList
  }
})
export default class Sidebar extends Vue {
  locations!: Location[];
  location!: Location;
  isAdmin!: boolean;
}
