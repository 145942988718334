
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import Cookies from 'js-cookie';
import { User } from '@/entities/User';

@Component({
  computed: mapGetters(['isLoggedIn', 'user']),
})
export default class UserMenu extends Vue {
  isLoggedIn!: boolean;
  user!: User;
  darkModeIcon = '';

  mounted() {
    const savedDarkMode = Cookies.get('darkMode');
    this.$vuetify.theme.dark = savedDarkMode === 'true';
    this.darkModeIcon = this.$vuetify.theme.dark
      ? 'mdi-white-balance-sunny'
      : 'mdi-moon-waning-crescent';
  }

  toggleDarkMode() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  }

  onDarkModeChange(newDarkMode: boolean) {
    Cookies.set('darkMode', newDarkMode.toString());
    this.darkModeIcon = newDarkMode
      ? 'mdi-white-balance-sunny'
      : 'mdi-moon-waning-crescent';
  }

  get formattedName(): string {
    if (this.isLoggedIn && this.user.name !== undefined) {
      const splitted = this.user.name.split(' ');

      const firstLetter = splitted[0].charAt(0);
      const lastLetter = splitted.pop()!.charAt(0);

      return `${firstLetter}${lastLetter}`;
    } else {
      return 'ERR';
    }
  }

  logout() {
    this.$store.dispatch('logout');
    this.$router.replace('/login');
  }

  beforeMount() {
    this.$watch('$vuetify.theme.dark', this.onDarkModeChange);
  }
}
