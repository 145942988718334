
import Vue from 'vue'
import Component from 'vue-class-component'

import { mapGetters } from 'vuex';

import UserMenu from "@/components/menus/UserMenu.vue";
import Snackbar from "@/components/Snackbar.vue";
import Sidebar from "@/components/Sidebar.vue";

import { User } from './entities/User';
import { Location } from './entities/Location';

@Component({
  name: "App",

  components: {
    UserMenu,
    Snackbar,
    Sidebar
  },

  computed: mapGetters([
    'isLoggedIn',
    'isAdmin',
    'user',
    'location'
  ])
})
export default class AppView extends Vue {
  drawer = false;

  isLoggedIn!: boolean;
  isAdmin!: boolean;
  user!: User;
  location!: Location;
}
