/**
 * Used to fetch, upload and edit audios
 */

import axios from 'axios';
import { bus, Colors } from "@/plugins/events";

import { Audio } from '@/entities/audio/Audio';

/**
 * ImageData that can be updated
 */
export interface AudioData {
  name?: string,
  category?: string,
  location?: string
}

export default class Audios {
  /**
   * List all the audios available to the user
   */
  async list(): Promise<Audio[]> {
    try {
      const response = await axios.get("/v1/audios");
      const audios: Audio[] = response.data.audios;

      return audios;
    } catch (e){
      bus.$emit("show-snack", {
        text: "Couldn't fetch audios",
        color: Colors.ERROR
      });

      throw Error(e as any)
    }
  }

  /**
   * Upload an audio
   */
  async upload(data: any): Promise<Audio> {
    try {
      const response = await axios.post("/v1/audios/upload", data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      bus.$emit("show-snack", {
        text: "Image was uploaded.",
        color: Colors.SUCCESS
      });

      const audio: Audio = response.data.audio;

      return audio;
    } catch {
      bus.$emit("show-snack", {
        text: "Audio couldn't be uploaded.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  /**
   * Edit an audio
   */
  async edit(file_name: string, data: AudioData) {
    try {
      await axios.patch(`/v1/audios/${file_name}`, data);

      bus.$emit("show-snack", {
        text: "Audio was updated.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit("show-snack", {
        text: "Couldn't update the Audio!",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

}
