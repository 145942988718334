/**
 * Prepare Vue
 */
 import Vue from 'vue';
 import VueRouter from 'vue-router';
 import store from '../store';

 import Users from "../plugins/services/users";

 Vue.use(VueRouter);

 /**
  * Meta configs
  */
 const guestMeta = {
   auth: false,
   guest: true
 };

 const authMeta = {
   auth: true,
   guest: false
 };

 /**
  * Hotel Routes
  */
 import Home from '@/views/Home.vue';
 const DevicesPage = () => import("@/views/devices/List.vue");
 const TrainingvideosPage = () => import("@/views/trainingvideos/List.vue");
 const HotelInfoPage = () => import("@/views/Info.vue");
 const HotelCustomizePage = () => import("@/views/customize/List.vue");
 const TicketsPage = () => import("@/views/tickets/List.vue");
 const RoomsPage = () => import("@/views/rooms/List.vue");

 const HotelRoutes = [
   {
     path: '/',
     name: 'Home',
     component: Home,
     meta: {
       title: "Dashboard"
     }
   },
   {
     path: '/devices',
     name: 'Devices',
     component: DevicesPage,
     meta: {
       title: "Devices",
       ...authMeta
     }
   },
   {
     path: '/trainingvideos',
     name: 'Training Videos',
     component: TrainingvideosPage,
     meta: {
       title: "Training Videos",
       ...authMeta
     }
   },
   {
     path: '/customize',
     name: 'Customize',
     component: HotelCustomizePage,
     meta: {
       title: "Customize your hotel",
       ...authMeta
     }
   },
   {
     path: '/info',
     name: 'Info',
     component: HotelInfoPage,
     meta: {
       title: "Hotel Info",
       ...authMeta,
       roles: ["admin", "operator"]
     }
   },
   {
     path: '/tickets',
     name: 'Tickets',
     component: TicketsPage,
   },
   {
    path: '/rooms',
    name: 'Rooms',
    component: RoomsPage,
    meta: {
      title: "Room Media",
      ...authMeta
    }
   },
 ]

 /**
  * Users routes
  */
 const LoginPage = () => import("@/views/users/Login.vue");
 const RegisterPage = () => import("@/views/users/Register.vue");

 const usersRoutes = [
   {
     path: '/login',
     name: 'Login',
     component: LoginPage,
     meta: {
       ...guestMeta
     }
   },
   {
     path: '/register',
     name: 'Register',
     component: RegisterPage,
     meta: {
       ...authMeta,
       title: "Register",
       roles: ["operator"]
     }
   }
 ];

 /**
  * Services routes
  */
 const ServiceList = () => import("@/views/services/List.vue");
 const ServiceCreate = () => import("@/views/services/Create.vue");

 const servicesRoutes = [
   {
     path: '/services',
     name: 'services',
     component: ServiceList,
     meta: {
       ...authMeta,
       title: "Services"
     },
   },
   {
     path: '/services/create',
     name: 'service-create',
     component: ServiceCreate,
     meta: {
       ...authMeta,
       title: "Services - Create"
     }
   },
   {
     path: '/services/edit',
     name: "service-edit",
     component: ServiceCreate,
     meta: {
       ...authMeta,
       title: "Services - Edit"
     }
   }
 ];

 /**
  * Images routes
  */
 const ImagesList = () => import("@/views/images/List.vue");
 const ImagesView = () => import("@/views/images/View.vue");

 const imagesRoutes = [
   {
     path: '/images',
     name: "images",
     component: ImagesList,
     meta: {
       ...authMeta,
       title: "Images"
     }
   },
   {
     path: '/images/:id',
     name: "images-view",
     component: ImagesView,
     meta: {
       ...authMeta,
       title: "Images - View"
     }
   }
 ]

 /**
  * Videos routes
  */
  const VideosList = () => import("@/views/videos/List.vue");
  const VideosView = () => import("@/views/videos/View.vue");

  const videosRoutes = [
    {
      path: '/videos',
      name: "videos",
      component: VideosList,
      meta: {
        ...authMeta,
        title: "Videos"
      }
    },
    {
      path: '/videos/:id',
      name: "videos-view",
      component: VideosView,
      meta: {
        ...authMeta,
        title: "Videos - View"
      }
    }
  ]

 /**
  * Accounts routes
  */
 const AccountEdit = () => import("@/views/accounts/Edit.vue");
 const AccountsList = () => import("@/views/accounts/List.vue");

 const accountsRoutes = [
   {
     path: '/account',
     name: "account",
     component: AccountEdit,
     meta: {
       ...authMeta,
       title: "Account"
     }
   },
   {
     path: '/accounts',
     name: 'accounts',
     component: AccountsList,
     meta: {
       ...authMeta,
       title: "Accounts",
       roles: ["operator"]
     }
   }
 ];

 /**
  * Snacks routes
  */
 const Snackslist = () => import("@/views/snacks/List.vue");
 const SnacksCreate  = () => import("@/views/snacks/Create.vue");

 const snacksRoutes = [
   {
     path: '/snacks',
     name: "snacks",
     component: Snackslist,
     meta: {
       ...authMeta,
       title: "Snacks"
     }
   },
   {
     path: '/snacks/create',
     name: "snacks-create",
     component: SnacksCreate,
     meta: {
       ...authMeta,
       title: "Snacks - Create"
     },
   },
   {
     path: '/snacks/edit',
     name: "snacks-edit",
     component: SnacksCreate,
     meta: {
       ...authMeta,
       title: "Snacks - Edit"
     }
   }
 ];

 /**
  * Restaurants routes
  */
 const RestaurantsList = () => import("@/views/restaurants/List.vue");
 const RestaurantsCreate  = () => import("@/views/restaurants/Create.vue");

 const restaurantsRoutes = [
   {
     path: '/restaurants',
     name: "restaurants",
     component: RestaurantsList,
     meta: {
       ...authMeta,
       title: "Restaurants"
     }
   },
   {
     path: '/restaurants/create',
     name: "restaurants-create",
     component: RestaurantsCreate,
     meta: {
       ...authMeta,
       title: "Restaurants - Create"
     },
   },
   {
     path: '/restaurants/edit',
     name: "restaurants-edit",
     component: RestaurantsCreate,
     meta: {
       ...authMeta,
       title: "Restaurants - Edit"
     }
   }
 ];


 const UnifiPortal  = () => import("@/views/Unifi/Portal.vue");
 const VoucherList  = () => import("@/views/vouchers/List.vue");

 /**
  * Music routes
  */
 const AudioList = () => import("@/views/audio/List.vue");
 const AudioCreate  = () => import("@/views/audio/View.vue");

 const audioRoutes = [
   {
     path: '/audios',
     name: "audio",
     component: AudioList,
     meta: {
       ...authMeta,
       title: "Audios"
     }
   },
   {
     path: '/audios/:id',
     name: "audio-edit",
     component: AudioCreate,
     meta: {
       ...authMeta,
       title: "Audio - Edit"
     }
   }
 ];

 const routes = [
   ...HotelRoutes,
   ...usersRoutes,
   ...servicesRoutes,
   ...imagesRoutes,
   ...accountsRoutes,
   ...snacksRoutes,
   ...restaurantsRoutes,
   ...videosRoutes,
    ...audioRoutes,
   {
    path: '/guest/s/:siteId/',

    name: "unifi-portal",
    component: UnifiPortal,
    props: true,
    meta: {
      title: "Unifi Portal"
    },

  },
   {
    path: '/vouchers',

    name: "vouchers",
    component: VoucherList,
    props: true,
    meta: {
      ...authMeta,
      title: "Vouchers"
    }

  },
   {
     path: '*',
     name: 'not-found',
     redirect: '/'
   }
 ];

 /**
  * Configure VueRouter
  */
 const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes,
 });

 router.beforeEach(async (to, from, next) => {
   let token: string | null = store.getters.token;
   const lang = to.params.lang


   if (token !== null && !store.getters.isLoggedIn) {

     try {
       const user = await new Users().loginWithToken(token);

       store.dispatch('login', user);
     } catch (err) {
       store.dispatch('logout');

       next("/");
     }
   }

   if (to.matched.some(route => route.meta.auth)) {
     if (token === null) {
       return next({
         path: '/login',
         params: { nextUrl: to.fullPath }
       });
     }
     if (to.meta!.roles) {
       let user = store.getters.user;

       if (!to.meta!.roles.includes(user.type.toLowerCase())) {
         return next('/');
       }
     }
     next();
   } else if (to.matched.some(route => route.meta.guest)) {
     if (token === null) {
       return next()
     }

     next('/');
   } else {
    next()

   }
 });

 export default router;
