
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

@Component({
  computed: mapGetters([
    'locations',
    'isAdmin',
    '$vuetify.theme'
  ]),
})
export default class HotelList extends Vue {
  locations!: Location[];
  isAdmin!: boolean;
  hotelList: boolean = false;

  get isDarkMode(): boolean {
    return this.$vuetify.theme.dark;
  }

  selectLocation(uuid: string) {
    this.$store.dispatch('setLocation', {
      uuid
    });
  }
}
