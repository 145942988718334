/**
 * Used to fetch, upload and edit rooms
 * Zoey Désautels (zoey.desautels@konek.ai), 2020
 * Maxime Aké (maxime.ake@konek.ai), 2021
 */

import axios from 'axios';
import { bus, Colors } from "@/plugins/events";

import { Room } from '@/entities/devices/Room';

export interface RoomData {
  checked_in?: boolean;
  language?: string;
  customer?: string;
  message?: string;
  cleanup?: number;
  cleanup_name?: string;
  services_enabled?: boolean;
  lock_device?: boolean;
  unavailable?: number;
  note?: string;
  custom_media_type?: string;
  custom_media_url?: string;
  boot_on_tv?: boolean;
  tv_channel?: string;
}

export default class Rooms {
  /**
   * Edit a Room
   */
  async edit(room: Room, data: RoomData, show_snack_on_success:boolean = true): Promise<void> {
    try {
      axios.patch(`/v1/rooms/${room.id}`, data);
      if (show_snack_on_success) {
        bus.$emit('show-snack', {
          text: `Room ${room.room_number} was updated.`,
          color: Colors.SUCCESS
        });
      }
    } catch {

      bus.$emit('show-snack', {
        text: `Couldn't update room ${room.room_number}.`,
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  async editMany(rooms: Room[], datas: RoomData[]): Promise<void> {
    try {
      for (let i = 0; i < rooms.length; i++) {
        await axios.patch(`/v1/rooms/${rooms[i].id}`, datas[i]);
      }
      bus.$emit('show-snack', {
        text: `Rooms were updated.`,
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit('show-snack', {
        text: `Couldn't update rooms.`,
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  
}

