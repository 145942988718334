/**
 * Used to fetch, patch and delete ratings
 * Zoey Désautels (zoey.desautels@konek.ai), 2020
 */

import axios from 'axios';
import { bus, Colors } from "@/plugins/events";
import { Rating } from '@/entities/restaurant/Rating';

export interface RatingData {
  name?: string;
  text?: string;
  note?: number;
  restaurant?: number;
}

export default class Ratings {
  async create(data: Rating): Promise<void> {
    try {
      await axios.post(`/v1/ratings`, data);

      bus.$emit('show-snack', {
        text: "Rating was created.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't create rating.",
        color: Colors.ERROR
      });

      throw Error();
    } 
  }

  async delete(rating: Rating): Promise<void> {
    try {
      await axios.delete(`/v1/ratings/${rating.id}`);

      bus.$emit('show-snack', {
        text: "Rating was deleted.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit('show-snack', {
        text: "Couldn't delete rating.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }
}