/**
 * Global plugin to use the Konek API
 * Zoey Désautels (zoey.desautels@konek.ai), 2020
 */
import Vue from 'vue';

import Locations from './services/locations';
import Users from './services/users';
import Socket from './services/socket';

import Kokotes from './services/devices/kokotes';
import Rooms from './services/devices/rooms';

import Images from './services/images/images';
import Services from './services/directory/services';

import Food from './services/restaurant/food';
import Snacks from './services/restaurant/snack';
import Ratings from './services/restaurant/ratings';
import Restaurants from './services/restaurant/restaurant';
import PdfMenus from './services/pdfMenus/pdfMenus';
import Unifi from './services/Unifi/unifi';
import Videos from './services/videos/videos';
import Audios from './services/audios/audios';

const KonekAPI = {
  install(Vue: any) {
    Vue.prototype.$locations = new Locations();
    Vue.prototype.$users = new Users();
    Vue.prototype.$socket = new Socket();

    Vue.prototype.$kokotes = new Kokotes();
    Vue.prototype.$rooms = new Rooms();

    Vue.prototype.$images = new Images();
    Vue.prototype.$pdfMenus = new PdfMenus();
    Vue.prototype.$services = new Services();
    Vue.prototype.$videos = new Videos();
    Vue.prototype.$audios = new Audios();

    Vue.prototype.$food = new Food();
    Vue.prototype.$snacks = new Snacks();
    Vue.prototype.$ratings = new Ratings();
    Vue.prototype.$restaurants = new Restaurants();
    Vue.prototype.$unifi = new Unifi();
  }
}

Vue.use(KonekAPI);
